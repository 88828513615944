@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
body{
    font-family: 'Roboto';
}
.limiter {
    width: 100%;
    margin: 0 auto;
}
.container-login{
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #FFF;
}
.wrap-login{
    width: 100%;
    background: #fff;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
}
.login-form{
    width: calc(100% - 560px);
    min-height: 100vh;
    display: flex;
    align-items: center;
    
}
.login-white-back{
    background-color: #FFF;
}
.login-dark-back{
    background-color: #1A1A1A;
}
.login-more{
    background-image: url('./assets/login-beach.png');
    width: 560px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.login-more::after {
    content:"";
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(255, 235, 17, 0.8);
    z-index:-1;
}
.logo{
    width: 66px;
    height: 66px;
    position: absolute;
    top: 33px;
    left: 37px;
}
.top-right-buttons{
    position: absolute;
    right: 24px;
    top: 0;
}
.text-top{
    font-family: 'Roboto', sans-serif;
    margin-top: 25px;
    size: 14px;
    line-height: 16px;
    text-align: center;
    color: #999;
}
.btn-transparent-pill{
    background-color: transparent;
    margin-top: 14px;
    border-radius: 40px;
    border: 1px solid #999;
    height: 37px;
    padding: 5px 28px;
    text-decoration: none !important;
}
.btn-transparent-pill p{
    font-family: 'Roboto', sans-serif;
    size: 14px;
    color: #999;
    margin: auto;
}
.r-12{
    margin-right: 12px;
}
.container>.form-group{
    max-width: 540px;
    margin: auto;
}
.input-group-addon>.form-control {
    border-radius: 10px 0 0 10px;
    border-right: none;
    height: 50px;
    width: 133px;
}
.phone{
    border-radius: 0 10px 10px 0 !important;
    height: 50px !important;
    font-family: 'Roboto';
    font-size: 14px;
    padding: .375rem .75rem !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    min-width: 70%;
    box-shadow: none !important;
}
.phone-modal{
    font-family: 'Roboto';
    font-size: 14px;
    border-radius: 0 10px 10px 0 !important;
    height: 50px !important;
    padding: .375rem .75rem !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    width: 60%;
    box-shadow: none !important;
}
.border-radius-10{
    border-radius: 10px !important;
}
.btn-primary-login{
    background-color: #4FB4E6;
    width: 224px;
    border-radius: 10px;
    border: none;
    height: 40px;
}
.btn-primary-login p{
    font-family: 'Roboto';
    color: #FFF;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: center;
    margin: auto;
}
.btn-secondary-login{
    background-color: #FFEB11;
    width: 224px;
    border-radius: 10px;
    border: none;
    height: 40px;
}
.btn-secondary-login p{
    font-family: 'Roboto';
    color: #000;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: center;
    margin: auto;
}
.text-form{
    color: #1A1A1A;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
}
.stebans-subtitle{
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 300;
    line-height: 21px;
    color: #999;
    text-align: center;
}
.stebans-title{
    font-size: 28px;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 34px;
    color: #000;
    text-align: center;
}
.stebans-title-white{
    font-size: 28px;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 34px;
    color: #FFF;
    text-align: center;
}
.m-t-42{
    margin-top: 42px !important;
}
.m-t-40{
    margin-top: 40px !important;
}
.m-t-37{
    margin-top: 37px !important;
}
.form-control:focus {
    box-shadow:none !important;
    border-color: #ced4da !important;
}
.react-code-input-mod, input:last-child{
    margin-right: 0px !important;
}
.text-resend{
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    color: #999;
    text-align: center !important;
}
.text-resend:hover{
    color: #999;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
    height: 50px !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}
.form-control-dark{
    background-color: #1A1A1A !important;
    border-color: #BDBDBD !important;
    color: #FFF !important;
}
.form-control-danger{
    background-color: #1A1A1A !important;
    border-color: #FF0F00 !important;
    color: #FFF !important;
}
.text-link{
    pointer-events: initial;
    cursor: pointer;
}
#sidebar {
    min-width: 300px !important;
    max-width: 300px !important;
    background: transparent !important;
    overflow-y: auto;
}
.content {
    background-color: #FBFBFB !important;
}
.content {
    width: 100% !important;
    padding: 0;
    min-height: 100vh !important;
    transition: all .3s !important;
}
.table-curved th:first-child {
    padding: 6px;
    border-radius: 10px 0 0 10px;
}
.table-curved th:last-child {
    border-radius: 0 10px 10px 0;
}
.rowBorderStart {
    border: 1px solid rgba(159, 159, 159, .2);
    border-right: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.rowBorderMiddle {
    border: 1px solid  rgba(159, 159, 159, .2);
    border-left: 0px;
    border-right: 0px;
}
.rowBorderEnd {
    border: 1px solid  rgba(159, 159, 159, .2);
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
table {
    border-collapse: separate !important;
    border-spacing: 0px 7px;
}


.container-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 21px;
  }
  
  /* Hide the browser's default checkbox */
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: -8px;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-checkbox input:checked ~ .checkmark {
    background-color: #4FB4E6;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container-checkbox .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

.modal-600w {
    max-width: 600px !important;
}
.modal-620w{
    max-width: 650px !important;
}
.react-tag-input__input{
    text-transform: uppercase;
    color: '#1A1A1A' !important;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
}
.react-tag-input {
    border-radius: 10px !important;
    height: 50px !important;
    border: 1px solid #BDBDBD !important;
}
.css-yk16xz-control {
    height: 50px !important;
    border-radius: 10px !important;
}
.css-1okebmr-indicatorSeparator{
    background-color: transparent !important;
}
.css-1pahdxg-control{
    border-radius: 10px !important;
    min-height: 50px !important;
    border: 1px solid #BDBDBD !important;
    box-shadow: none !important;
}
.css-1rhbuit-multiValue {
    background-color: #4FB4E6 !important;
    color: #FFF !important;
    border-radius: 10px !important;
}
.css-12jo7m5 {
    font-family: 'Roboto' !important;
    color: #FFF !important;
}
.css-xb97g8:hover {
    background-color: #4FB4E6 !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    color: #FFF !important;
}
.rc-time-picker {
    display: block !important;
}
.rc-time-picker-input {
    color: #1A1A1A !important;
    font-size: 14px !important;
    font-family: 'Roboto' !important;
    border-color: #BDBDBD !important;
    height: 50px !important;
    border-radius: 10px !important;
}
.rc-time-picker-clear {
    display: none !important;
}
.table-collapse{
    border-collapse: collapse !important;
}
.border-danger{
    border-color: #FF0F00 !important;
}
.form-control[type="text"]{
    font-size: 14px !important;
    height: 50px !important;
}
.form-control[type="password"]{
    font-size: 14px !important;
    height: 50px !important;
}
.form-control[type="number"]{
    font-size: 14px !important;
    height: 50px !important;
}
label{
    margin-bottom: .3rem !important;
}

/* bootstrap normally does this part for you, but for some reason it's not being applied */
select {
    border: 1px solid #999;
    border-radius: 8px;
    padding: 8px;
}

/* only apply this to firefox: */
@-moz-document url-prefix() {
/* Normally you can just apply this to all 'select's not just a classs selector */
    select.select {
        /* This removes the native down arrow: */
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: "";
        /* Add a new down arrow, probably shouldn't hotlink: */
        background-image: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-chevron-down-16.png");
        background-position: right 5px center;
        background-repeat: no-repeat;
    }
}
/* Safari selects are sort of ugly too.  Unfortunately this applies to both Safari *and* Chrome */
@media (-webkit-min-device-pixel-ratio: 0) {
    select {
        -webkit-appearance: none;
        background-image: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-chevron-down-16.png") !important;
        background-position: right 5px center;
        background-repeat: no-repeat;
        padding-right: 20px;
    }
}

.modal-backdrop.show{
    background-color: rgba(255, 255, 255, 0.85);
}

.image-center{
    object-fit: cover;
    object-position: center;
}
.modal-content {
    border-radius: 10px !important;
}
.modal-body{
    padding: 0 !important;
}
.phone{
    min-width: auto !important;
    width: 70%;
}
.input-group-prepend{
    width: 30%;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2{
    width: 100%;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2_mode{
    width: 65% !important;
}
.navbar-phone{
    display: none !important;
}
.button-right-md-sm{
    margin-right: 21px;
}
@media only screen and (max-width: 600px) {
    .wrap-login {
        flex-direction: column;
        width: 100%;
        display: block;
    }
    .login-form {
        width: 100%;
    }
    .phone{
        min-width: auto !important;
        width: 60% !important;
    }
    .input-group-prepend{
        width: 40% !important;
    }
    .ReactFlagsSelect-module_flagsSelect__2pfa2{
        width: 100%;
    }
    .navbar-phone{
        display: flex !important;
    }
    .content{
        padding-top: 61px !important;
    }
    .title-main{
        display: block !important;
        justify-content: center !important;
    }
    .mb-sm-10{
        margin-bottom: 10px;
    }
    .button-right-md-sm{
        margin-right: 0 !important;
    }
    .search-sm{
        margin-left: 0 !important;
    }
    .card-flex{
        width: 100% !important;
        margin: 10px !important;
    }
} 

@media only screen and (min-width: 600px) {
    
}

/* Customize the label (the container) */
.container-radio {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Roboto';
    color: #1A1A1A;
    line-height: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #BDBDBD;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .radiomark {
    background-color: #f2f2f2;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .radiomark {
    background-color: #4FB4E6;
    border: 1px solid #4FB4E6;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .radiomark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .radiomark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.ml-48{
    margin-left: 24px;
}
.mt-65{
    margin-top: 65px;
}
.mb-40{
    margin-bottom: 40px;
}
.mt-30{
    margin-top: 30px;
}
.mt-24{
    margin-top: 24px;
}
.mt-20{
    margin-top: 20px;
}
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;/*si no se declara añade los ... pero muestra todo el texto*/
}
.ellipsi{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cards{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.card-flex{
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 190px;
}
.dropzone-style{
    flex: 1;
    border-style: dotted;
    border-color: #BDBDBD;
    display: flex;
    width: 50%;
    border-radius: 10px;
}

/*-------------------------------------------->*/
.pagination-container {
    display: flex;
    list-style-type: none;
}
.pagination-container .pagination-item {
    padding: 0 12px;
    height: 32px;
    font-family: 'Roboto';
    text-align: center;
    margin: auto 4px;
    color: #828282;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 14px;
    min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
    background-color: transparent;
    cursor: default;
}
.pagination-container .pagination-item:hover {
    color: #1A1A1A;
    cursor: pointer;
}
.pagination-container .pagination-item.selected {
    color: #1A1A1A;
}
.pagination-container .pagination-item .arrow::before {
    position: relative;
   /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
    content: '';
   /* By using an em scale, the arrows will size with the font */
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(0, 0, 0, 0.87);
    border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
    transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
    pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
    border-right: 0.12em solid rgba(0, 0, 0, 0.43);
    border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
    background-color: transparent;
    cursor: default;
}
ul{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.react-tag-input__input {
    height: 50px !important;
}
.react-tag-input {
    padding: 0 .375em !important;
}
.react-tag-input__tag{
    font-size: 14px !important;
}
.css-12jo7m5 {
    font-size: 14px !important;
}
.react-tag-input__tag__content{
    font-size: 14px !important;
}
.react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__current-month{
    font-size: 14px !important;
    line-height: normal !important;
    font-family: 'Roboto' !important;
}
.react-datepicker__navigation{
    top: 8px !important;
}
.react-datepicker {
    font-family: 'Roboto' !important;
}
.react-tag-input::-webkit-scrollbar {
    display: none;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #FFEB11;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }